import axios from 'axios'
import qs from 'qs'
import merge from 'lodash/merge'
import { Loading, Message, MessageBox } from 'element-ui'
import router from '@/route/index'
// import VueCookie from 'vue-cookies';

// 如果是生产环境 使用.env.production配置文件中的VUE_APP_SERVER_URL作为请求前缀
// 如果是开发环境 使用.env.development配置文件中的VUE_APP_BASE_API作为前缀代理拦截
// const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_SERVER_URL : process.env.VUE_APP_BASE_API
const BASE_URL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_SERVER_URL : process.env.VUE_APP_BASE_API
// 跨域请求，允许保存cookie
axios.defaults.withCredentials = true
// 创建axios请求
const http = axios.create({
    // 设置超时时间
    timeout: 1000 * 30,
    // 跨域情况下带上cookie
    withCredentials: true,
    // 设置默认请求头，之后可以随便改
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
          'channel': 'pc',
    },
    // 配置是否使用默认api
    notUseDefaultApi: false
})

/**
 * 请求拦截，所有的请求通过此处
 */
let loading
http.interceptors.request.use(config => {
    // elementUI的loading组件
    let showLoading = false
    // 如果传入loading为true，把showLoading置为true
    if (config.loading === true) {
        showLoading = true
    }
    if (showLoading) {
        loading = Loading.service({
            // 显示在加载图标下方的加载文案
            text: config.loadingText || 'ruben正在全力为您加载中...',
            // 自定义加载图标类名
            spinner: 'el-icon-loading',
            // 遮罩背景色
            background: 'rgba(0, 0, 0, 0.7)'
        })
    }
    // 请求头带上token
    // config.headers.token = VueCookie.get('token')
    if (localStorage.getItem('token')) {
      config.headers.token = localStorage.getItem('token')
    } else {
      config.headers.token = null
    }
    // 判断是否使用全局地址，假设我们要请求其余域名，这里可以配置为true
    if (!config.notUseDefaultApi) {
        config.url = BASE_URL + config.url;
    }
    // 获取请求方式
    const method = config.method
    // 定义参数变量
    const params = {}
    // 对参数进行格式化，qs官网【https://www.npmjs.com/package/qs】
    // 从请求头获取数组格式化类型
    // 默认indices【ids[0]=1&ids[1]=2&ids[3]=3】
    // 可选 brackets【uds[]=1&ids[]=2&ids[]=3】
    // 以及 repeat【ids=1&ids=2&id=3】
    const arrayFormat = config.headers.arrayFormat || 'indices'
    if (method === 'post' && config.headers['Content-Type'] === 'application/x-www-form-urlencoded; charset=utf-8') {
        // post请求参数处理
        config.data = qs.stringify(config.data, {
            // allowDots为true时 data= 'a.b=c'  qs转换出来就是 a:{b:c}，可以进行深层次转换
            allowDots: true,
            arrayFormat: arrayFormat
        })
    } else if (method === 'get') {
        // get请求参数处理
        config.params = qs.stringify(config.params, {
            allowDots: true,
            arrayFormat: arrayFormat
        })
        config.params = qs.parse(config.params)
        config.params = merge(params, config.params)
    }
    console.log(config)
    return config
}, error => {
    return Promise.reject(error)
})

// elementUI错误弹框设置
// let errorMessageAlertOption = {
//     // 消息文字
//     message: '请求错误',
//     // 主题 success/warning/info/error 【default info】
//     type: 'error',
//     // 是否显示关闭按钮
//     showClose: true,
//     // 是否将 message 属性作为 HTML 片段处理
//     dangerouslyUseHTMLString: true,
//     // 显示时间, 毫秒。设为 0 则不会自动关闭
//     duration: 3000,
//     // 自定义图标的类名，会覆盖 type
//     customClass: 'el-icon-lightning'
// }
let messageInstance = null;
/**
 * 响应拦截，所有的相应通过此处
 */
http.interceptors.response.use(response => {
    // 如果此时还处于加载中
    if (loading) {
        // 停止加载
        loading.close();
    }
    if (response.data && response.data.success === false) {
        // 如果请求返回错误
        // 这里我们把错误通过elementUI的message组件弹框出来，不过我们需要设置一下消息
        messageInstance = response.data.message
      // // 执行弹框
      // if (messageInstance) {
      //   Message(errorMessageAlertOption).close();
      // }
      // messageInstance = Message(errorMessageAlertOption);
      if (messageInstance == 'token已过期') {
        //  Message({
        //   message: messageInstance,
        //   type: 'warn',
        //   duration: 2000,
        //   offset: 100
        // });
        MessageBox('你的账号已经在其他地方登录，请重新登录', '提示', {
          confirmButtonText:'确定'
        }).then(() => {
          localStorage.setItem('token', '')
          localStorage.setItem('username', '')
          localStorage.setItem('user_headimage', '')
          localStorage.setItem('user_id', '')
          router.replace({path:'/login'})
        })
      } else {
        Message({
        message: messageInstance,
        type: 'error',
        duration: 2000,
        offset: 100
      });
      }
      
    }
    return response
}, error => {
    // 如果此时还处于加载中
    if (loading) {
        // 停止加载
        loading.close();
    }
    console.log(error)
    // 梅开二度，弹框
    // errorMessageAlertOption.message = error.response.data.msg || error.response.data.exception || error.response.data || error.response || error
    // errorMessageAlertOption.duration = 4000;
    Message({
      message: messageInstance,
      type: 'error',
      duration: 2000,
      offset: 100
    });
    return Promise.reject(error)
})

export default http