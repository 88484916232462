import Vue from 'vue'
import VueRouter from 'vue-router'
import home from "@/assets/fa-home.svg"
import people from "@/assets/md-people.svg"
import article from '@/assets/riLine-article-line.svg'
import bell from '@/assets/fa-bell.svg'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '官网',
    component: () => import('@/layout/layout.vue'),
    redirect: '/login',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () =>
          import('@/views/login/login.vue'),
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index.vue'),
        redirect: '/dashboard/briefIntroduction',
        children: [
          {
            path: '/dashboard/briefIntroduction',
            name: 'briefIntroduction',
            component: () =>
              import('@/views/dashboard/briefIntroduction.vue'),
            meta:{title:'村落简介',icon:(home)}
          },
          {
            path: '/dashboard/biography',
            name: 'biography',
            component: () =>
              import('@/views/dashboard/biography.vue'),
            meta:{title:'人物传记',icon:(people)}
          },
          {
            path: '/dashboard/villageNews',
            name: 'villageNews',
            component: () =>
              import('@/views/dashboard/villageNews.vue'),
            meta:{title:'村讯',icon:(article)}
          },
          {
            path: '/dashboard/message',
            name: 'message',
            component: () =>
              import('@/views/dashboard/message.vue'),
            meta:{title:'系统消息',icon:(bell)}
          }
        ]
      }
    ]
  },
  {
    path: '/privacyClause',
    name: '隐私条款',
    component: () => import('@/views/privacyClause/index.vue'),
  }
]

const router = new VueRouter({
  // 页面刷新白屏问题
  mode: 'history',
  base: '/',
  routes
})
const whiteList = ['/login','/privacyClause']
router.beforeEach((to, from, next) => {
  console.log(from);
  if (localStorage.getItem('token')) {
    if (to.path === '/login') {
      next('/dashboard')
    } else {
      next()
    }
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  }
})
export default router