<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
*{
  margin: 0;
  padding: 0;
}
html,body{
  padding-right: 0px !important;
}
.ql-indent-1 {
        padding-left: 0px !important;
        text-indent: 1em !important;
    }

    .ql-indent-2 {
        padding-left: 0px !important;
        text-indent: 2em !important;
    }

    .ql-indent-3 {
        padding-left: 0px !important;
        text-indent: 3em !important;
    }

    .ql-indent-4 {
        padding-left: 0px !important;
        text-indent: 4em !important;
    }

    .ql-indent-5 {
        padding-left: 0px !important;
        text-indent: 5em !important;
    }

    .ql-indent-6 {
        padding-left: 0px !important;
        text-indent: 6em !important;
    }

    .ql-indent-7 {
        padding-left: 0px !important;
        text-indent: 7em !important;
    }

    .ql-indent-8 {
        padding-left: 0px !important;
        text-indent: 8em !important;
    }
</style>
